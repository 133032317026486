@tailwind base;
@tailwind components;
@tailwind utilities;

/* Enable "Lato" font */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@layer base {
  html {
    font-family: 'Lato', system-ui, sans-serif;
  }
}
html.dark ::-webkit-calendar-picker-indicator {
  filter: invert(1)
}
html.dark ::-webkit-datetime-bar {
  filter: invert(1)
}

/* Setup project */
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

/* Avoid white background when darkmode enable */
html.dark {
  background-color: rgb(17 24 39);
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 7px;
  transition: 0.4s ease-in-out;
}
::-webkit-scrollbar-track {
  background: #a8a8a8;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  background: #666666;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
