@keyframes wiggle {
    0% {
        transform: translateX(-5px);
    }
    50% { 
        transform: translateX(5px);
    }
    100% {
        transform: translateX(-5px);
    }
}

.animate-wiggle {
    animation: wiggle 0.2s ease-in-out 0s 1;
}